<template>
  <div>
    {{ formData }}
    <lims-date-picker
      range
      dataType="date"
      v-model="formData.datePickerRange"
      format="DD/MM/YYYY"
      :placeholder="$t('global/placeholder.dateRangePicker')"
    ></lims-date-picker>
    <lims-date-picker
      dataType="datetime"
      v-model="formData.dateTimePicker"
      format="DD/MM/YYYY HH:mm:ss"
    ></lims-date-picker>
    <date-picker
      :lang="{
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      }"
      slot="field"
      v-model="formData.dob"
      format="DD/MM/YYYY"
      tabenable="yes"
    ></date-picker>
    {{ fromDateTimezoneToIsoString(formData.dob) }} - {{ new Date(formData.dob) }} -
    {{ new Date(formData.dob).toISOString() }}
  </div>
</template>

<script>
import moment from 'moment';

const fromISOToCurrentTimezone = (isoString) => {
  const datetimeValues = isoString.split('T');

  return new Date(moment(datetimeValues[0]));
};
const convertFromDateTimezoneToIsoString = (datetime) => {
  // format YYYY-MM-DDT00:00:00.000Z
  if (datetime instanceof Date) {
    const year = datetime.getFullYear();
    let month = (datetime.getMonth() + 1).toString();
    if (month.length < 2) {
      month = `0${month}`;
    }
    let date = datetime.getDate().toString();
    if (date.length < 2) {
      date = `0${date}`;
    }
    return `${year}-${month}-${date}T00:00:00.000Z`;
  }
  return datetime;
};
export default {
  methods: {
    fromDateTimezoneToIsoString: function (datetime) {
      return convertFromDateTimezoneToIsoString(datetime);
    },
  },
  data: function () {
    return {
      formData: {
        dateTimePicker: '2023-02-19T17:00:00.000Z',
        datePickerRange: ['2023-02-19T16:00:00.000Z', '2023-02-20T16:00:00.000Z'],
        dob: fromISOToCurrentTimezone('2023-02-22T00:00:00.000Z '), // convert qua timezone hien tai tren may user
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
